
import $app from '@/plugins/modules';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Admin } from '@/modules/admin'
import { ICompanyService } from '@/modules/system/types';

@Component
export default class CompanyServiceDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => null })
  readonly service: ICompanyService | undefined;

  admin = $app.module(Admin);

  tab = 0;
  item: ICompanyService | null = null;
  reseting = false;
  pwd = '';

  @Watch('value')
  onShow() {
    if (this.value) {
      this.item = $app.clone(this.service);
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  get active(): boolean {
    return this.item?.state === 'a';
  }

  set active(value: boolean) {
    this.item!.state = value ? 'a' : 'b';
  }

  store() {
    this.$emit('store', this.item)
  }

  remove() {
    this.$emit('remove', this.item)
  }
}
